import React, { useCallback, useEffect, useState } from 'react';
import './App.css';
import '../Forms/Forms.scss';
import { useSelector, useDispatch } from 'react-redux';
import { Sidebar } from '../Sidebar/Sidebar';
import { Header } from '../Header/Header';
import { MainContent } from '../MainContent/MainContent';
import { toggleNavbar } from '../../Store/actions/navbar';
import Media from 'react-media';
import { MenuLinks } from '../MenuLinks/MenuLinks';
import { useHistory } from "react-router-dom";
import 'moment/locale/es';
import config from '../../config';
import { setEndpoint } from '../../Store/actions/pusher';
import { Auth } from '../../Lib/Auth';


const App = function(){
	const history = useHistory();
	
	const isLogged = useSelector(state => state.auth.logged);
	const pusher = useSelector(state => state.pusher);
	const [pusherDone, setPusherDone] = useState(false);
	const [doingPusher, setDoingPusher] = useState(false);
	const [initialAuth, setInitialAuth] = useState(isLogged);
	const isNavbarOpen = useSelector(state => state.navbar);
	const dispatch = useDispatch();
	
	useEffect(() => {
		if(doingPusher || !isLogged)
			return;
		
		if(pusher.enabled){
			
			if(pusherDone)
				return;
			
			setDoingPusher(true);
			
			navigator.serviceWorker.ready
			.then(reg =>
				reg.pushManager.subscribe({
					userVisibleOnly: true,
					applicationServerKey: config.VAPIDPublicKey
				})
			)
			.then(sub => {
				dispatch(setEndpoint(sub.endpoint));
				return Auth.updatePusherEndpoint(sub.toJSON())
			})
			.then(r => {
				setPusherDone(true);
				setDoingPusher(false);
			})
			.catch(e => {
				setDoingPusher(false);
			});
		}else if(pusher.endpoint.length > 1){
			setDoingPusher(true);
			
			Auth.unsubPusher(pusher.endpoint)
			.then(() => {
				dispatch(setEndpoint(""));
				setDoingPusher(false);
				setPusherDone(false);
			})
			.catch(e => {
				dispatch(setEndpoint(""));
				setDoingPusher(false);
				setPusherDone(false);
			});
		}
		
	}, [pusherDone, doingPusher, pusher, isLogged, dispatch]);
	
	const toggle = useCallback(() => dispatch(toggleNavbar()) , [dispatch]);
	const theme = useSelector(state => state.theme.color);
	
	useEffect(() => {
		if(initialAuth && !isLogged)
			history.push('/');
		
		setInitialAuth(isLogged);
	}, [initialAuth, isLogged, history])
	
	return (
		<div className={`App theme-${theme}`}>
			<div className={ `app-container ${ isLogged ? 'logged' : '' }` } >
				
				{ isLogged && <Sidebar>
					<MenuLinks />
				</Sidebar>}
				
				{ isNavbarOpen && 
					<Media query="(max-width : 768px)">
						<div className="overlay" onClick={toggle}></div>
					</Media>
				}
				
				{ isLogged && <Header/> }
				
				<MainContent />
			</div>
		</div>
	);
}

export default App;
