import React from 'react';
import { ProjectUpdateSkeleton } from '../../Components/ProjectUpdate/ProjectUpdateSkeleton';


const skeleton = [
	<div key={0} className="col-12 col-md-3 col-lg-4">
		<ProjectUpdateSkeleton animate={true}/>
	</div>,
	<div key={1} className="col-12 col-md-3 col-lg-4">
		<ProjectUpdateSkeleton animate={true}/>
	</div>,
	<div key={2} className="col-12 col-md-3 col-lg-4">
		<ProjectUpdateSkeleton animate={true}/>
	</div>,
	<div key={3} className="col-12 col-md-3 col-lg-4">
		<ProjectUpdateSkeleton animate={true}/>
	</div>,
	<div key={4} className="col-12 col-md-3 col-lg-4">
		<ProjectUpdateSkeleton animate={true}/>
	</div>,
	<div key={5} className="col-12 col-md-3 col-lg-4">
		<ProjectUpdateSkeleton animate={true}/>
	</div>,
	<div key={6} className="col-12 col-md-3 col-lg-4">
		<ProjectUpdateSkeleton animate={true}/>
	</div>,
	<div key={7} className="col-12 col-md-3 col-lg-4">
		<ProjectUpdateSkeleton animate={true}/>
	</div>,
	<div key={8} className="col-12 col-md-3 col-lg-4">
		<ProjectUpdateSkeleton animate={true}/>
	</div>,
	<div key={9} className="col-12 col-md-3 col-lg-4">
		<ProjectUpdateSkeleton animate={true}/>
	</div>,
	<div key={10} className="col-12 col-md-3 col-lg-4">
		<ProjectUpdateSkeleton animate={true}/>
	</div>,
	<div key={11} className="col-12 col-md-3 col-lg-4">
		<ProjectUpdateSkeleton animate={true}/>
	</div>,
	<div key={12} className="col-12 col-md-3 col-lg-4">
		<ProjectUpdateSkeleton animate={true}/>
	</div>,
	<div key={13} className="col-12 col-md-3 col-lg-4">
		<ProjectUpdateSkeleton animate={true}/>
	</div>
];

export default React.memo(() => (
	<div className="container-fluid py-2 h-100">
			<div className="row">
				{ skeleton }
			</div>
		</div>
	)
);