function urlBase64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - base64String.length % 4) % 4);
	const base64 = (base64String + padding)
		.replace(/-/g, '+')
		.replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (var i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
};

const object = {
	dictKey : "dict://",
	reCaptcha : "6LfaqMAUAAAAAA3pzxGRa_Qsxvqavdd9W1xHAWGh",
	localstorageKey : "briquev1",
	endpoints: Object.freeze({		
		auth : 'auth',
		users : 'users',
		projects : 'projects',
		projectUpdates : 'projectupdates',
		projectUsers : 'projectusers',
		updateMedia : 'updatemedia',
		tags : 'tags',
	}),
	dictionaryIndexes : Object.freeze({
		users : 0,
		projects : 0,
		projectupdates : 0,
		projectusers : 0,
		updatemedia : 0,
		tags : 0
	}),
	VAPIDPublicKey : urlBase64ToUint8Array('BPW-b3uJNC0NwE37wfzjTz4NPglFjRqAzwDWF2a3Ce5EjYGSI9evqP1Urzla474og3rcYGTgAGUyxloxRX1AtSM')
};

switch(process.env.NODE_ENV){
	case 'production':
		object.apiURL = "https://clientes.briqueinmobiliaria.mx/api/v1/";
		object.uploadsURL = "/uploads/";
		break;
	default:
		object.apiURL = "http://localhost/brique/v1/";
		object.uploadsURL = "http://localhost/brique/uploads/";
		break;
}

Object.freeze(object);
export default object;