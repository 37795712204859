import React from 'react';
import loadable from "@loadable/component";

const skellyStyle = {
	display : "inline-block",
	width : "100%",
	height : "200px"
};

const SkeletonOfTheSkeleton = React.memo(p => <span className="ghostLoad" style={skellyStyle}></span> );

export const ProjectUpdateSkeleton = loadable(() => import("./_ProjectUpdateSkeleton"), {fallback : <SkeletonOfTheSkeleton/>});