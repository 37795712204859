import './MainContent.css';
import { useSelector } from 'react-redux';
import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { useLocation, useHistory, Switch, Route, Redirect } from "react-router-dom";
import { LazyLoader } from "../LazyLoader/LazyLoader";
import LoginSkeleton from "../../Routes/Login/LoginSkeleton";
import ABMSkeleton from "../../Routes/ABM/ABMSkeleton";
import ABMUpdateSkeleton from "../../Routes/ABMUpdate/ABMUpdateSkeleton";
import ABMDeleteSkeleton from "../../Routes/ABMDelete/ABMDeleteSkeleton";
import MyUserSkeleton from "../../Routes/MyUser/MyUserSkeleton";
import { ProjectUpdateSkeleton } from "../ProjectUpdate/ProjectUpdateSkeleton";
import WallSkeleton from "../../Routes/Wall/WallSkeleton";
import NewPostSkeleton from "../../Routes/NewPost/NewPostSkeleton";
import { ErrorScreen } from "../ErrorScreen/ErrorScreen";
import { Modal } from "../Modal/Modal";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

library.add(faTimes);

export const MainContent = React.memo(function(){
	const renderArea = useRef(null);

	const history = useHistory();
	const location = useLocation();
	const background = location.state && location.state.background;

	const isLogged = useSelector(state => state.auth.logged);
	const authLevel = useSelector(state => state.auth.user.type);
	const [prevPath, setPrevPath] = useState(location.pathname);

	const goBack = useCallback(e => {
		if(history.length === 0){
			history.push("/");
		}else{
			history.goBack();
		}
	}, [history]);

	useEffect(() => {
		if(background || prevPath === location.pathname) return;

		renderArea.current.scrollTop = 0;
		setPrevPath(location.pathname);
	}, [prevPath, background, location.pathname]);

	const routes = useMemo(() => {


		const r = [<Route key="404" path="/404">
			<ErrorScreen title={"notfound.error.title"}
				description={"notfound.error.description"}
				action={"notfound.error.action"}/>
		</Route>];

		if(!isLogged){
			r.push(<Route key="restaurar" path="/restaurarcontrasena/:jwt">
				<LazyLoader component={() => import("../../Routes/RestorePassword/RestorePassword")} fallback={<LoginSkeleton/>} />
			</Route>);

			r.push(<Route key="olvide" path="/olvidemicontrasena">
				<LazyLoader component={() => import("../../Routes/ForgotPassword/ForgotPassword")} fallback={<LoginSkeleton/>} />
			</Route>);

			r.push(<Route key="login" path="/" exact={true}>
				<LazyLoader component={() => import("../../Routes/Login/Login")} fallback={<LoginSkeleton/>} />
			</Route>);

			r.push(
				<Route key="matchAll" path="*">
					<Redirect to={{pathname: "/404"}}/>
				</Route>
			);
			return r;
		}


		const authL = parseInt(authLevel);

		if(authL === 1){
			r.push(<Route key="abm" path="/abm/:table">
				<LazyLoader component={() => import("../../Routes/ABM/ABM")} fallback={<ABMSkeleton/>}/>
			</Route>);

			r.push(<Route key="abmprojects" path="/projects">
				<LazyLoader component={() => import("../../Routes/ABMProjects/ABMProjects")} fallback={<ABMSkeleton/>}/>
			</Route>);

			r.push(<Route key="abmprojectuserss" path="/projectmembers/:project">
				<LazyLoader component={() => import("../../Routes/ABMProjectUsers/ABMProjectUsers")} fallback={<ABMSkeleton/>}/>
			</Route>);

			r.push(<Route key="abmupdate" path="/abmupdate/:table/:id">
				<LazyLoader component={() => import("../../Routes/ABMUpdate/ABMUpdate")} fallback={<ABMUpdateSkeleton/>}/>
			</Route>);

			r.push(<Route key="abmdelete" path="/abmdelete/:table/:id">
				<LazyLoader component={() => import("../../Routes/ABMDelete/ABMDelete")} fallback={<ABMDeleteSkeleton/>}/>
			</Route>);
		}

		r.push(
			<Route key="myuser" path="/miusuario" >
				<LazyLoader component={() => import("../../Routes/MyUser/MyUser")} fallback={<MyUserSkeleton/>} />
			</Route>
		);
		r.push(
			<Route key="nuevaactualizacion" path="/nuevaactualizacion">
				<LazyLoader component={() => import("../../Routes/NewPost/NewPost")} fallback={<NewPostSkeleton/>} />
			</Route>
		);

		r.push(
			<Route key="nuevodocument" path="/nuevodocument">
				<LazyLoader component={() => import("../../Routes/NewDocument/NewDocument")} fallback={<NewPostSkeleton/>} />
			</Route>
		);

		r.push(
			<Route key="iosInstall" path="/iosInstall">
				<LazyLoader component={() => import("../../Routes/iOSInstall/iOSInstall")} fallback={<NewPostSkeleton/>} />
			</Route>
		);

		r.push(
			<Route key="projectpost" path="/projectpost/:id">
				<LazyLoader component={() => import("../../Routes/ProjectPost/ProjectPost")} fallback={<ProjectUpdateSkeleton/>} />
			</Route>
		);

		r.push(
			<Route key="documentwall" path="/documents" exact={true}>
				<LazyLoader component={() => import("../../Routes/DocumentWall/DocumentWall")} fallback={<WallSkeleton/>} />
			</Route>
		);

		r.push(
			<Route key="wall" path="/" exact={true}>
				<LazyLoader component={() => import("../../Routes/Wall/Wall")} fallback={<WallSkeleton/>} />
			</Route>
		);

		r.push(
			<Route key="tags" path="/tag/:tag" exact={true}>
				<LazyLoader component={() => import("../../Routes/Wall/Wall")} fallback={<WallSkeleton/>} />
			</Route>
		);

		r.push(
			<Route key="matchAll" path="*">
				<Redirect to={{pathname: "/404"}}/>
			</Route>
		);
		return r;
	}, [isLogged, authLevel]);



	return (
		<div ref={renderArea} className="main-content">
			<Switch location={background || location}>
				{ routes }
			</Switch>
			{background &&
				<Modal outCallback={goBack} iconButton="times">
					<Switch>
						{ routes }
					</Switch>
				</Modal>
			}
		</div>
	);
});
