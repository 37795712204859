import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import 'intersection-observer';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Media from 'react-media';
import App from './Components/App/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './Store';
import { getLang } from './Lib/Lang';
import { BrowserRouter as Router } from 'react-router-dom';
import ReduxToastr, { toastr } from 'react-redux-toastr';
import { addSuccessResponseHook, addErrorResponseHook, addErrorRequestHook } from './Lib/API';

addErrorRequestHook((e) => {
	if(e.name === 'AbortError')
		return;
	
	getLang(store.getState().lang)
	.then(lang => {
		toastr.error(lang['error.connection']);
	});
});

addErrorResponseHook((r) => {
	if('mes' in r)
		toastr.error(r.mes);
});

addSuccessResponseHook((r) => {
	if('mes' in r)
		toastr.warning(r.mes);
});

ReactDOM.render(
	<Provider store={store}>
		<Router>
			<App />
		</Router>
		<Media query="(max-width : 768px)">
			{matches => 
				matches ? 
				<ReduxToastr
					timeOut={5000}
					newestOnTop={true}
					preventDuplicates
					position="bottom-center"
					getState={(state) => state.toastr} // This is the default
					transitionIn="fadeIn"
					transitionOut="fadeOut"
					progressBar
					closeOnToastrClick/>
				:
				<ReduxToastr
					timeOut={5000}
					newestOnTop={true}
					preventDuplicates
					position="top-right"
					getState={(state) => state.toastr} // This is the default
					transitionIn="fadeIn"
					transitionOut="fadeOut"
					progressBar
					closeOnToastrClick/>
			}
		</Media>
	</Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
	onSuccess : reg => {
		
		
	},
	onUpdate : reg => {
		getLang(store.getState().lang)
		.then(lang => {
			toastr.success(lang['newVersion.title'], lang['newVersion.mes'], {timeOut : 0});
		});
	}
});